.glossy {
  position: relative;
  overflow: hidden;
}

.glossy::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1), transparent);
  animation: glossy-shine 3s infinite;
}

@keyframes glossy-shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.shadow-blue-500 {
  filter: drop-shadow(0px 0px 10px rgba(59, 130, 246, 0.5));
}

.shadow-purple-500 {
  filter: drop-shadow(0px 0px 10px rgba(139, 92, 246, 0.5));
}

.shadow-yellow-500 {
  filter: drop-shadow(0px 0px 10px rgba(234, 179, 8, 0.5));
}

.shadow-gray-500 {
  filter: drop-shadow(0px 0px 10px rgba(75, 85, 99, 0.5));
}

.shadow-green-500 {
  filter: drop-shadow(0px 0px 10px rgba(34, 197, 94, 0.5));
}

.shadow-orange-500 {
  filter: drop-shadow(0px 0px 10px rgba(249, 115, 22, 0.5));
}

.shadow-red-500 {
  filter: drop-shadow(0px 0px 10px rgba(220, 38, 38, 0.5));
}

.shadow-indigo-500 {
  filter: drop-shadow(0px 0px 10px rgba(99, 102, 241, 0.5));
}
